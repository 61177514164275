div.placeholder_parent {
	margin: 16px;
}

div.desktop728x90_placeholder {
	width: 728px;
	height: 90px;
	margin: 0 auto;
	border: 4px dashed blue;
}

div.mobile300x250_Placeholder {
	width: 300px;
	height: 250px;
	margin: 0 auto;
	border: 4px dashed blue;
}