.content {
	margin: 20px;
}

.hor_btn {
	margin-left: 5px;
}

.layoutFrame {
	text-align: center;
}
